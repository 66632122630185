!((document, Drupal, $) => {
  'use strict';

  /**
   * Javascript behavior for initializing program cards slider
   * on mobile only.
   */
  Drupal.behaviors.lesson = {
    /* eslint-disable max-len */

    // Define the desktop media query.
    desktopMQ: window.matchMedia('(min-width: 1024px)'),

    attach: function(context) {
      const $node = $('.node-lesson, .node-podcast', context);

      if (!$node.length) {
        return;
      }

      // Set the unwrapping on resize
      this.desktopMQ.addListener(this.handleResize.bind(this));

      if (this.desktopMQ.matches) {
        this.wrap($node);
      }

    },

    unwrap: function($node) {
      const $left = $node.find('.l-teaching-guide, .l-lesson-details');
      const $right = $node.find('.l-author, .l-more-like-this');

      if ($left.closest('.l-desktop-left').length) {
        $left.unwrap();
      }
      if ($right.closest('.l-desktop-right').length) {
        $right.unwrap();
      }
    },

    wrap: function($node) {

      if (!$node.find('.l-desktop-left').length) {
        $node
          .find('.l-teaching-guide, .l-lesson-details')
          .wrapAll('<div class="l-desktop-left" />');
      }

      if (!$node.find('.l-desktop-right').length) {
        $node
          .find('.l-author, .l-more-like-this')
          .wrapAll('<div class="l-desktop-right" />');
      }
    },

    handleResize: function() {
      const $node = $('.node-lesson, .node-podcast');

      if (this.desktopMQ.matches) {
        this.wrap($node);
      }
      else {
        this.unwrap($node);
      }
    }

  };
})(document, Drupal, jQuery);
